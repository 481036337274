import VueRouter from "vue-router";
import { Auth } from "aws-amplify";

const routes = [
  {
    // RiskScore.us homepage - main public landing
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: { layout: "AppLayoutPublic", navItems: null },
  },
  {
    // RiskScore.us/survey
    // Where the user takes survey
    path: "/score/:id?",
    name: "PublicScoreSurvey",
    component: () => import("../views/PublicScoreSurvey.vue"),
    meta: { layout: "AppLayoutPublic", navItems: null },
  },
  {
    // RiskScore.us/your-score-report
    // Where the user gets their results report
    path: "/your-score-report/:id?",
    name: "PublicProspectScoreReport",
    props: true,
    component: () => import("../views/PublicProspectScoreReportV3.vue"),
    meta: { layout: "AppLayoutScoreReportV3", navItems: null },
  },
  {
    path: "/report/:id?",
    name: "PublicProspectScoreReportV3",
    props: true,
    component: () => import("../views/PublicProspectScoreReportV3.vue"),
    meta: { layout: "AppLayoutScoreReportV3", navItems: null },
  },
  {
    path: "/score-report/:id?",
    name: "PublicProspectScoreReportTest",
    props: true,
    component: () => import("../views/PublicProspectScoreReport.vue"),
    meta: { layout: "AppLayoutScoreReportV3", navItems: null },
  },
  {
    path: "/contact-requested/:id",
    name: "PublicProspectContactRequested",
    props: true,
    component: () => import("../views/PublicProspectContactRequested.vue"),
    meta: { layout: "AppLayoutPublic", navItems: null },
  },
  {
    path: "/reduce-risk/:id",
    name: "PublicProspectContactPostSurvey",
    props: true,
    component: () => import("../views/PublicProspectContactPostSurvey.vue"),
    meta: { layout: "AppLayoutPublic", navItems: null },
  },
  // {
  // RiskScore.us Advisors Page - main public landing
  //   path: "/advisor",
  //   name: "Advisor",
  //   component: () => import("../views/PublicAdvisor.vue"),
  //   meta: { layout: "AppLayoutPublic", navItems: null },
  // },
  {
    path: "/admin",
    name: "AdminDashboard",
    component: () => import("../views/AdminDashboard.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/auth",
    name: "AdminAuth",
    component: () => import("../views/AdminAuth.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  {
    path: "/lead-sheet/:id",
    name: "LeadSheet",
    component: () => import("../views/LeadSheet.vue"),
    meta: { authRequired: true, layout: "AppLayoutDefault" },
  },
  {
    path: "/intel",
    // props: true,
    component: () => import("../views/Intel.vue"),
    meta: { authRequired: true, allowedGroups: ["SuperAdmin"] },
    children: [
      {
        path: "",
        name: "Analytics",
        component: () => import("../views/IntelIndex.vue"),
        meta: { allowedGroups: ["SuperAdmin"] },
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import("../views/IntelReports.vue"),
      },
      {
        path: "list",
        name: "List",
        component: () => import("../views/IntelTable.vue"),
      },
      {
        path: "leaderboard",
        name: "Leaderboard",
        component: () => import("../views/IntelLeaderboard.vue"),
      },
      {
        path: "calendar",
        name: "Calendar",
        component: () => import("../views/IntelCalendar.vue"),
      },
      {
        path: "products",
        name: "Products",
        component: () => import("../views/IntelProducts.vue"),
      },
      {
        path: "detail/:id",
        name: "IntelDetail",
        props: true,
        component: () => import("../views/IntelDetail.vue"),
      },
      {
        path: "signin",
        name: "SignIn",
        component: () => import("../views/IntelFauxSignIn.vue"),
      },
      {
        path: "advisor",
        name: "Advisor",
        component: () => import("../views/IntelAdvisorDashboard.vue"),
        children: [
          {
            path: "prospect/prospectId?",
            component: () => import("../views/IntelProspectDetail.vue"),
            meta: {
              breadCrumb: "Detail",
            },
          },
        ],
      },
      {
        path: "resources",
        component: () => import("../views/IntelResources.vue"),
        meta: {
          breadCrumb: "Resources",
        },
        children: [
          {
            path: "scripts",
            component: () => import("../views/IntelResourcesScripts.vue"),
            meta: {
              breadCrumb: "Scripts",
            },
          },
          {
            path: "videos",
            component: () => import("../views/IntelResourcesVideos.vue"),
            meta: {
              breadCrumb: "Videos",
            },
          },

          {
            path: "methodology",
            component: () => import("../views/Methodology.vue"),
            meta: {
              breadCrumb: "Methodology",
            },
          },
        ],
      },
      // {
      //   path: "prospect/:prospectId?",
      //   component: () => import("../views/IntelProspectDetail.vue"),
      //   meta: {

      //     breadCrumb: "Detail",
      //   },
      //   // props: true,
      // },
      {
        path: "presenter/:advisorId?",
        component: () => import("../views/IntelPresenter.vue"),
        meta: {},
      },
    ],
  },
  {
    path: "/admin/advisors",
    name: "AdminAdvisorManagement",
    component: () => import("../views/AdminAdvisorManagement.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/advisor/edit/:id?",
    name: "AdminAdvisorEdit",
    component: () => import("../views/AdminAdvisorAddEdit.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/advisor/add/",
    name: "AdminAdvisorAdd",
    component: () => import("../views/AdminAdvisorAddEdit.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/prospects",
    name: "AdminProspectManagement",
    component: () => import("../views/AdminProspectManagement.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/reporting",
    name: "AdminReporting",
    component: () => import("../views/AdminReporting.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/import",
    name: "Import",
    component: () => import("../views/AdminImport.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/prospect-invite",
    name: "AdminProspectInvite",
    component: () => import("../views/AdminProspectInvite.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/report-editor",
    name: "AdminReportContentEditor",
    component: () => import("../views/AdminReportContentEditor.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/report-templates",
    name: "AdminReportTemplates",
    component: () => import("../views/AdminReportTemplates.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/report-template-builder/:templateId?",
    name: "AdminReportTemplateBuilder",
    component: () => import("../views/AdminReportTemplateBuilder.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
    props: true,
  },
  {
    path: "/advisor",
    name: "AdvisorDashboard",
    component: () => import("../views/AdvisorDashboard.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["Advisor"],
    },
  },

  {
    path: "/advisor/auth/:organizationId?",
    name: "AdvisorAuth",
    component: () => import("../views/AdvisorAuth.vue"),
    meta: { layout: "AppLayoutPublic" },
    props: true,
  },
  {
    path: "/security-statement",
    name: "SecurityStatement",
    component: () => import("../views/LegalSecurityStatement.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/LegalPrivacyPolicy.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  {
    path: "/methodology",
    name: "Methodology",
    component: () => import("../views/Methodology.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // base: process.env.BASE_URL,
  routes,
});

// if (process.env.VUE_APP_SCORE_ENV !== "production") {
//   router.addRoute({
//     path: "/report/:id?",
//     name: "PublicProspectScoreReportV3",
//     props: true,
//     component: () => import("../views/PublicProspectScoreReportV3.vue"),
//     meta: { layout: "AppLayoutScoreReportV3", navItems: null },
//   });
// }

router.beforeResolve(async (to, from, next) => {
  console.log("to --> ", to);

  if (to.matched.some((route) => route.meta.authRequired)) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          // If user, they are either SuperAdmin or Advisor
          const isSuperAdmin =
            user.signInUserSession?.accessToken?.payload[
              "cognito:groups"
            ].includes("SuperAdmin");

          console.log(
            "User is a: ",
            user.signInUserSession?.accessToken?.payload["cognito:groups"],
            to.meta.allowedGroups
          );

          // Path only for SuperAdmins
          if (to.meta.allowedGroups.includes("SuperAdmin") && isSuperAdmin) {
            console.log("ONE");
            // Authenticated, but need to be superAdmin. Must be a SuperAdmin, all to proceed
            return next();
          } else {
            console.log("TWO");
            // Authenticated, but not superAdmin only. Must be an Advisor, allow to proceed
            return next({ name: "AdvisorAuth" });
          }
        } else {
          // Unauthenticated User
          console.log("Auth Route, unauth user");

          next();
        }
      })
      .catch(() => {
        // AUTH REQUIRED. NO USER. SEND HOME
        next({
          name: "Home",
        });
      });
  } else {
    // Path without Auth required
    console.log("PUBLIC ROUTE");
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          const isSuperAdmin =
            user.signInUserSession?.accessToken?.payload[
              "cognito:groups"
            ].includes("SuperAdmin");

          // Authenticated, send to respective dashboard if lands on Auth/AdvisorAuth

          // if path is AdminAuth, check if they are a SA, if not they are advisors
          if (to.name === "AdminAuth") {
            if (isSuperAdmin) {
              // SuperAdmin
              return next({ name: "AdminDashboard" });
            } else {
              // Advisor
              return next({ name: "AdvisorDashboard" });
            }
          } else if (to.name === "AdvisorAuth") {
            if (!isSuperAdmin) {
              // Advisor
              return next({ name: "AdvisorDashboard" });
            } else {
              // SuperAdmin
              return next({ name: "AdminDashboard" });
            }
          } else {
            // Authenticated and Public Path
            return next();
          }
        } else {
          // Unauthenticated adn Public Path
          return next();
        }
      })
      .catch((error) => {
        console.warn(error);
        next();
      });
  }
});

export default router;
