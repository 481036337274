import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#178AF4",
        secondary: "#222222",
        accent: "#9806db",
        admin: "#091437",
      },
    },
  },
});
