<template>
  <BaseAppLayout>
    <router-view></router-view>
  </BaseAppLayout>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
// const defaultLayout = "AppLayoutDefault";
export default {
  name: "App",

  data: () => ({
    signedIn: false,
    layout: `div`,
  }),
  computed: {
    // layout() {
    //   const layout = this.$route.meta.layout || defaultLayout;
    //   return () => import(`@/layouts/${layout}.vue`);
    // },
    navItems() {
      return this.$route.meta.navItems;
    },
  },
  beforeCreate() {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        this.signedIn = true;

        this.$router.push("/admin");
      }
      if (payload.event === "signOut") {
        // this.$router.push("/auth");
        this.signedIn = false;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
      })
      .catch(() => (this.signedIn = false));
  },
  created() {
    console.log("APP Created");
  },
};
</script>
<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "@/scss/styles.scss";
</style>
