import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // isAuthenticated: false,
    currentIntel: null,
    intelUser: null,
    routeLevelCount: null,
    currentAdvisorId: null,
  },
  mutations: {
    // SET_IS_AUTHENTICATED(state, payload) {
    //   state.isAuthenticated = payload;
    // },
    SET_CURRENT_INTEL(state, payload) {
      state.currentIntel = Object.assign({}, payload);
    },
    SET_INTEL_USER(state, payload) {
      state.intelUser = payload;
    },
    SET_ROUTE_LEVEL_COUNT(state, payload) {
      state.routeLevelCount = payload;
    },
    SET_CURRENT_ADVISOR_ID(state, payload) {
      state.currentAdvisorId = payload;
    },
    RESET_INTEL_STATE(state) {
      state.currentIntel = null;
      state.intelUser = null;
      state.currentAdvisorId = null;
    },
  },
  actions: {
    // setIsAuthenticated({ commit }, payload) {
    //   return new Promise((resolve) => {
    //     commit("SET_IS_AUTHENTICATED", payload);
    //     resolve();
    //   });
    // },
    setCurrentIntel({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_CURRENT_INTEL", payload);
        resolve();
      });
    },
    setIntelUser({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_INTEL_USER", payload);
        resolve();
      });
    },
    setRouteLevelCount({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_ROUTE_LEVEL_COUNT", payload);
        resolve();
      });
    },
    setCurrentAdvisorId({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_CURRENT_ADVISOR_ID", payload);
        resolve();
      });
    },
    resetIntelState({ commit }, payload) {
      return new Promise((resolve) => {
        commit("RESET_INTEL_STATE", payload);
        resolve();
      });
    },
  },
  // modules: {},
});
